<template>
  <div class="my-order-component">
    <v-row justify="center" align="center">
      <v-col>
        <div class="text-center logo">
          <vuetify-logo />
        </div>
        <div class="content" v-if="allOrders.length > 0">
          <h3 class="text-center">طلباتي</h3>
          <v-expansion-panels class="boxs" v-model="panel" multiple>
            <v-expansion-panel
              class="box"
              v-for="item in allOrders"
              :key="item.id"
            >
              <v-expansion-panel-header class="content-box">
                <div :class="item.status + ' status'">
                  <p class="state-text">{{ item.order_status }}</p>
                </div>
                <div class="date-time">
                  <span class="date"
                    ><i class="fas fa-calendar-alt icon-date"></i>
                    {{ item.date }}</span
                  >
                  <span class="time"
                    ><i class="far fa-clock icon-date"></i>
                    {{ item.time }}</span
                  >
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="des">
                  <p
                    class="product-name"
                    v-for="(product, index) in item.products"
                    :key="index"
                  >
                    {{ product.pivot.quantity }} -
                    {{ product.name | strippedContent }}
                  </p>
                  <hr />
                  <p class="order-number">
                    رقم الطلب : <strong dir="ltr">{{ item.order_id }}</strong>
                  </p>
                  <p class="order-total">التكلفة : {{ item.sub_total }} ريال</p>
                  <div class="btns-actions">
                    <v-row>
                      <v-col cols="12" md="6" class="text-center">
                        <button
                          class="green-btn check custom-btn"
                          v-if="
                            item.payment_method == 'bank_transfer' &&
                            item.status == 'pending'
                          "
                          @click="confirmPayment(item.id)"
                        >
                          تأكيد الدفع <i class="fas fa-check"></i>
                        </button>
                      </v-col>
                      <v-col cols="12" md="6" class="text-center">
                        <button
                          class="close-btn custom-btn"
                          v-if="
                            item.payment_method == 'bank_transfer' &&
                            item.status == 'pending'
                          "
                          @click="cancelOrder(item.id)"
                        >
                          الغاء <i class="fas fa-times"></i>
                        </button>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-else>
          <h3 class="text-center">لا يوجد طلبات</h3>
          <h3 class="text-center" style="text-align: center; margin-top: 20px">
            <a href="/" style="text-decoration: none">العودة للصفحة الرئيسية</a>
          </h3>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="color" top right>
      {{ messsage }}
    </v-snackbar>
  </div>
</template>

<script>
import VuetifyLogo from "../components/VuetifyLogo.vue";
import { ServiceFactory } from "../services/ServiceFactory";
const Service = ServiceFactory.get("orders");
export default {
  data() {
    return {
      panel: [],
      allOrders: [],
      dataLoading: false,
      snackbar: false,
      color: "success",
      messsage: "",
    };
  },
  components: {
    VuetifyLogo,
  },
  filters: {
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
  },
  created() {
    if (this.$route) {
      if (localStorage.getItem("token")) {
        this.fetchAllItems();
      } else {
        this.$router.push("/login");
      }
    }
  },
  methods: {
    async fetchAllItems() {
      this.dataLoading = true;
      const data = await Service.getAllOrders();
      this.allOrders = data.data;
      this.dataLoading = false;
    },
    async cancelOrder(id) {
      if (confirm("هل انت متأكد من الغاء هذا الطلب؟")) {
        this.dataLoading = true;
        const data = await Service.cancelOrder(id);
        if (data.status.error == false) {
          this.color = "success";
          this.messsage = "تم الغاء الطلب";
          this.snackbar = true;
        } else {
          this.color = "error";
          this.messsage = data.status.messsage;
          this.snackbar = true;
        }
        this.fetchAllItems();
        this.dataLoading = false;
      }
    },
    confirmPayment(id) {
      this.$router.push("/paymentMethod?confirm=true&orderId=" + id);
    },
  },
  mounted() {},
};
</script>
<style>
.des {
  /* #cd4431 */
  /* display: none; */
}
.my-order-component .table {
  margin-top: 50px !important;
}
.logo {
  margin-top: 15px;
}
.content {
  max-width: 100%;
  margin: auto;
  margin-top: 30px;
  color: #888;
}

.box {
  position: relative;
  padding: 20px;
  background: #fff;
  border-radius: 15px;
  margin: 20px auto;
  padding-bottom: 50px;
}
.box .content-box .status {
  width: 108px;
  height: 75px;

  color: #fff;

  text-align: center;
  border-radius: 7px;
  position: absolute;
  right: -57px;
  background: #6c6c6c;
}
.box .content-box .status.pending {
  background: #e4515b;
}
.box .content-box .status.checking_payment {
  background: #f5a719;
}
.box .content-box .status.cancelled {
  background: #cd4431;
}
.box .content-box .status.paid,
.box .content-box .status.delivered {
  background: #4c9e53;
}
.box .content-box .status.waiting_for_shipping {
  background: #40b9c5;
}
.box .content-box .status .state-text {
  display: block;
  color: #fff;
  margin-top: 19%;
  font-size: 16px;
  font-weight: 600;
}
.box .content-box .date-time {
  margin-right: 50px;
}
.box .content-box .date-time .date {
  display: block;
  margin-bottom: 10px;
}
.box .content-box .icon {
  position: absolute;
  left: 26px;
  top: 34px;
}
.icon-date {
  margin-left: 7px;
}
.box .des {
  background: #ddd;
  margin-top: 32px;
  padding: 10px 13px 10px 13px;
  /* margin-right: 48px; */
  border-radius: 5px;
}
.box .des p {
  color: #5c5757;
}
.box .des .order-number {
  margin: 20px 0 20px 0;
}
.box .des .order-total {
  font-weight: 600;
  font-size: 16px;
  color: #5fad66;
}
.custom-btn {
  padding: 5px 20px;
  border-radius: 5px;
  margin: 0 10px 10px 20px;
  color: #fff;
  min-width: 135px;
}
.close-btn {
  background: #e4515b;
}
.icon-open {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .box {
    padding: 25px;
    padding-bottom: 50px;
  }
  .content {
    max-width: 100%;
  }
  .box .content-box .icon {
    left: 11px;
    /* top: 5px; */
  }
  .box .content-box .date-time {
    margin-left: 20px;
  }
}
</style>
